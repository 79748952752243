var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('validation-observer',{ref:"addSchoolValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',[_c('b-row',[_c('b-col',[_c('h3',[_vm._v(_vm._s(_vm.$t("general.add_school")))])]),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('general.go_back')))])],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.school_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"school_type"}},[_vm._v(_vm._s(_vm.$t('general.school_type')))]),_c('v-select',{attrs:{"clearable":false,"options":_vm.schoolTypes,"label":"Name","id":"school_type"},model:{value:(_vm.selectedSchoolType),callback:function ($$v) {_vm.selectedSchoolType=$$v},expression:"selectedSchoolType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"id":"active"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._v(_vm._s(_vm.$t('general.active')))])],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('general.school_title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"school_title"}},[_vm._v(_vm._s(_vm.$t('general.school_title')))]),_c('b-form-input',{attrs:{"id":"school_title"},model:{value:(_vm.school_title),callback:function ($$v) {_vm.school_title=$$v},expression:"school_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('general.school_short'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"school_short"}},[_vm._v(_vm._s(_vm.$t('general.school_short')))]),_c('b-form-input',{attrs:{"id":"school_short"},model:{value:(_vm.school_short),callback:function ($$v) {_vm.school_short=$$v},expression:"school_short"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.school_user'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"school_user"}},[_vm._v(_vm._s(_vm.$t('general.school_user')))]),_c('b-form-input',{attrs:{"id":"school_user"},model:{value:(_vm.school_user),callback:function ($$v) {_vm.school_user=$$v},expression:"school_user"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t('general.password')))]),_c('b-input-group',{attrs:{"id":"password"}},[_c('b-form-input',{model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.generatePassword}},[_vm._v(" "+_vm._s(_vm.$t('general.generate'))+" ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }