<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="addSchoolValidation">
            <b-form @submit.prevent>
                <b-card>
                    <b-row>
                        <b-col><h3>{{ $t("general.add_school") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols='12' sm='6'>
                            <validation-provider
                                :name="$t('general.school_type')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='school_type'>{{ $t('general.school_type') }}</label>
                                <v-select :clearable='false' :options='schoolTypes' label="Name" id='school_type' v-model="selectedSchoolType"></v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols='12' sm='6'>
                            <b-form-checkbox class="mt-2" id='active' v-model="active">{{ $t('general.active') }}</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col>
                            <validation-provider
                                :name="$t('general.school_title')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='school_title'>{{ $t('general.school_title') }}</label>
                                <b-form-input id='school_title' v-model="school_title"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col>
                            <validation-provider
                                :name="$t('general.school_short')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='school_short'>{{ $t('general.school_short') }}</label>
                                <b-form-input id='school_short' v-model="school_short"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>

                    <hr>

                    <b-row>
                        <b-col cols='12' sm='6'>
                            <validation-provider
                                :name="$t('general.school_user')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='school_user'>{{ $t('general.school_user') }}</label>
                                <b-form-input id='school_user' v-model="school_user"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
           
                        <b-col cols='12' sm='6' lg="6"
                               class="mb-1"
                        >
                            <validation-provider
                                :name="$t('general.password')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <!-- button on right -->
                                <label for='password'>{{ $t('general.password') }}</label>
                                <b-input-group id='password'> 
                                    <b-form-input  v-model="password" />
                                    <b-input-group-append>
                                        <b-button variant="outline-primary" @click="generatePassword">
                                            {{ $t('general.generate') }}
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col class="text-right">
                            <b-button @click="validationForm" variant="primary">{{ $t('general.add') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BForm, BFormInput, BFormCheckbox, BInputGroup, BInputGroupAppend, BButton} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required } from '@validations'
    export default {
        components: {BCol,
                     BRow,
                     BOverlay,
                     BCard,
                     BForm,
                     BFormInput,
                     BFormCheckbox,
                     BInputGroup,
                     BInputGroupAppend,
                     BButton,
                     vSelect,
                     ValidationProvider,
                     ValidationObserver},
        data() {
            return {
                school_title: '',
                school_short: '',

                showLoader: false,
                schoolTypes: [],
                selectedSchoolType: null,
                active: true,
                school_user: '',
                password: '',

                required
            }
        },
        methods: {
            validationForm() {
                this.$refs.addSchoolValidation.validate().then((response) => {
                    if (response) {
                        this.onAddSchool()
                    }
                })
            },
            generatePassword() {
                let generatedPassword           = ''
                const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!"#$%&/()=?*'
                const charactersLength = characters.length
                const passwordLength = 15
                for (let i = 0; i < passwordLength; i++) {
                    generatedPassword += characters.charAt(Math.floor(Math.random() * charactersLength))
                }
                this.password = generatedPassword
            },
            onAddSchool() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Name: thisIns.school_title,
                    ShortName: thisIns.school_short,
                    TypeID: thisIns.selectedSchoolType.ID,
                    User: {
                        Username: thisIns.school_user,
                        password: thisIns.password
                    },
                    Active: thisIns.active
                }
                thisIns.$http.put('/v1/admin/sola', payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.school_successfully_added')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.push({name: 'school-list'})
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getSchoolTypes() {
                const thisIns = this
                thisIns.showLoader = true

                thisIns.$http.get('/v1/admin/vrstaSole')
                    .then(res => {
                        thisIns.schoolTypes = res.data
                        thisIns.selectedSchoolType = thisIns.schoolTypes[0]
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        created()  {
            this.getSchoolTypes()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>